<template>
    <div>
        <Filters
            @actionSearch="handleSubmitFormFilters"
            @actionClear="clearFilters"
            :disableHr="true"
            :leftButtons="true"
        >
            <b-form-group label="Nome do Grupo" label-for="nomeGrupo" label-class="font_size_label">
                <b-form-input
                    id="nomeGrupo"
                    v-model="filtroGrupo"
                    placeholder="Nome do Grupo"
                    autocomplete="off"
                    maxlength="255"
                />
            </b-form-group>
        </Filters>
        <b-col  v-if="gruposFiltrados.length == 0" class="p-0 d-flex align-items-center" cols="11">
            <h5 class="my-1 mr-1">Não há resultados para o termo: </h5>
            <h5 class="text-custom-blue m-0">{{ filtroGrupo }}</h5>
        </b-col>
    </div>
</template>

<script>
import Filters from '@/views/components/custom/filters/Filters.vue';
import { BCol, BFormGroup, BFormInput } from "bootstrap-vue";

export default {
    components: {
        BCol,
        Filters,
        BFormGroup, 
        BFormInput
    },

    props: {
        gruposCampanha: {
            type: Array,
            required: true,
        },
        search: {
            type: Boolean,
            required: false,
        }
    },

    data() {
        return {
            gruposFiltrados: {},
            filtroGrupo: ''
        }
    },

    methods: {
        handleSubmitFormFilters() {
            const filtro = this.filtroGrupo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

            this.gruposFiltrados = this.gruposCampanha.filter(grupo => {
                return grupo.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").
                toLowerCase().
                includes(filtro)
            });

            this.$emit('gruposFitlrados', this.gruposFiltrados);
        },

        clearFilters() {
            this.filtroGrupo = '';
            this.gruposFiltrados = this.gruposCampanha;

            this.$emit('gruposFitlrados', this.gruposFiltrados);
        },
    },

    watch: {
        search() {
            this.handleSubmitFormFilters();
        }
    }
}
</script>