<template>
  <div :key="updatedAt">
    <FilterGroups 
      :gruposCampanha="grupos"
      @gruposFitlrados="handleFiltroGrupo"
    />
    <b-row v-if="grupos.length == 0">
      <b-col>
        <h5>Por favor, selecione um ou mais grupos</h5>
      </b-col>
    </b-row>

    <app-collapse v-else>
      <div v-for="grupo in gruposFiltrados" :key="grupo.id_grupo">
        <TabPrecificacaoGrupo
          v-if="!grupo.removido"
          :grupo="grupo"
          :vacina="vacina"
          @priceComplete="setPriceComplete"
        />
      </div>
    </app-collapse>
  </div>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BCollapse,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BForm,
} from "bootstrap-vue";
import {
  CampanhaNacionalGetGruposSelecionadoPorVacina,
  CampanhaNacionalGetState,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ricon from "@/assets/images/icons/RIcon.svg";
import TabPrecificacaoValorEmpresa from "./TabPrecificacaoValorEmpresa.vue";
import TabPrecificacaoValorOperador from "./TabPrecificacaoValorOperador.vue";
import TabPrecificacaoDocumento from "./TabPrecificacaoDocumento.vue";
import TabPrecificacaoGrupo from "./TabPrecificacaoGrupo.vue";
import FilterGroups from '@/views/custom-pages/campanha-nacional/components/FilterGroup.vue';

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCol,
    BCollapse,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BForm,
    AppCollapse,
    AppCollapseItem,
    TabPrecificacaoValorEmpresa,
    TabPrecificacaoValorOperador,
    TabPrecificacaoDocumento,
    TabPrecificacaoGrupo,
    FilterGroups
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Ricon,
      moneyMask: {
        decimal: ",",
        thousands: ".",
        precision: 2,
      },
      updatedAt: null,
      grupos: [],
      key: 0,
      priceCompleteByGroup: [],
      keyProcessadas: [],
      visibilidadeValores: {},
      firstTime: true,
      gruposFiltrados: {}
    };
  },

  mounted() {
    this.grupos = CampanhaNacionalGetGruposSelecionadoPorVacina(this.vacina);
    this.grupos.forEach((grupo) => {
      this.visibilidadeValores[grupo.id_grupo] = false;
    });

    this.gruposFiltrados = this.grupos;
  },

  methods: {
    updateKey(val) {
      this.key = val;
    },

    setPriceComplete(val) {
      this.priceCompleteByGroup[val.id_grupo] = val;

      const valoresCompletos = this.priceCompleteByGroup.filter(
        (price) => price.completo
      );

      let grupos = this.grupos.filter((grupo) => !grupo.removido);

      const completo = {
        id_vacina: this.vacina.id_vacina,
        completo: true,
      };
      const incompleto = {
        id_vacina: this.vacina.id_vacina,
        completo: false,
      };

      this.$emit(
        "priceComplete",
        valoresCompletos.length >= grupos.length ? completo : incompleto
      );
    },

    toggleVisibilidade(grupo) {
      this.visibilidadeValores[grupo.id_grupo] =
        !this.visibilidadeValores[grupo.id_grupo];
    },

    handleFiltroGrupo(gruposFiltrados) {
      this.gruposFiltrados = gruposFiltrados;
    }
  },
  computed: {
    priceCompleteByGroupComputed() {
      return this.priceCompleteByGroup;
    },
  },
  watch: {
    "$store.state.campanhaNacionalState.watchers.grupos": {
      immediate: false,
      handler() {
        this.grupos = CampanhaNacionalGetGruposSelecionadoPorVacina(
          this.vacina
        );
        this.updatedAt = CampanhaNacionalGetState().updatedAt;
      },
    },
  },
};
</script>
