<template>
   <div class="text-left mt-2">
        <b-button
            v-if="stepsData.activeTabIndex > 0"
            @click="stepsData.prevTab()"
            squared
            variant="none"
            class="m-0 text-dark-blue"
            :class="stepsData.activeTabIndex == 1 ? 'borderButton': ''" 
        >
            <div class="d-flex">
                <feather-icon icon="ArrowLeftIcon" class="mr-50"/>
                <span class="align-middle textButton">Voltar</span>
            </div>
        </b-button>
        <b-button
            v-if="!stepsData.isLastStep"
            @click="stepsData.nextTab()"
            squared
            variant="none"
            class="m-0 text-dark-blue"
            :class="stepsData.activeTabIndex == 1 ? 'pl-1': 'pl-0'"
        >
            <div class="d-flex">
                <span class="align-middle textButton">Avançar</span>
                <feather-icon icon="ArrowRightIcon" class="mr-50"/>
            </div>
        </b-button>
    </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
    components: { BButton },

    props: {
        stepsData: {
            required: true
        } 
    }
}
</script>

<style scoped>
    .borderButton {
        border-right: 1px solid #2772C052;
        padding-right: 0.5rem;
    }

    .textButton {
        margin-right: 8px;
        font-size: 1.1rem;
    }
</style>