// eslint-disable-next-line import/no-unresolved
import '@/assets/scss/sweetalerts.scss'
import Swal from 'sweetalert2'

const successIcon = require('@/assets/custom-icons/check-success.png')
const successCheckCircleIcon = require('@/assets/custom-icons/check-circle.png')
const exclamationWarningIcon = require('@/assets/custom-icons/exclamation-warning.png')
const triangleWarningIcon = require('@/assets/custom-icons/alert-triangle.png')
const exclamationInfoIcon = require('@/assets/custom-icons/exclamation-info-icon.png')
const exclamationErrorIcon = require('@/assets/custom-icons/exclamation-error.png')
const errorIcon = require('@/assets/custom-icons/error-x.png')

const iconHtml = (icon, width) => `<img width="${width || 80}" src="${icon}" alt="" />`

export const errorMessage = (title, message) => Swal.fire({
  iconHtml: iconHtml(errorIcon),
  title: title || '',
  html: `${message || ''}`,
  confirmButtonText: 'Ok',
  customClass: {
    confirmButton: 'btn btn-custom-blue',
  },
})

export const successMessage = (message, title) => Swal.fire({
  iconHtml: iconHtml(successIcon, 60),
  title: title || '',
  html: `${message || ''}`,
  confirmButtonText: 'Ok',
  customClass: {
    confirmButton: 'success-outline-button-sweet',
  },
})

export const successCheckCircleMessage = (message, title) => Swal.fire({
  iconHtml: iconHtml(successCheckCircleIcon),
  title: title || '',
  html: `${message || ''}`,
  confirmButtonText: 'Ok',
  customClass: {
    confirmButton: 'confirm-button-sweet',
  },
})

export const successCheckCircleMessageConfirmAction = (message, title) => new Promise(resolve => {
  Swal.fire({
    iconHtml: iconHtml(successCheckCircleIcon),
    title: title || '',
    html: `${message || ''}`,
    confirmButtonText: 'Ok',
    customClass: {
      confirmButton: 'confirm-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const warningMessage = message => Swal.fire({
  iconHtml: iconHtml(exclamationWarningIcon),
  html: `${message || ''}`,
  confirmButtonText: 'Ok',
  customClass: {
    confirmButton: 'confirm-button-sweet',
  },
})

export const triangleWarnMessage = message => Swal.fire({
  iconHtml: iconHtml(triangleWarningIcon),
  html: `${message || ''}`,
  confirmButtonText: 'Ok',
  customClass: {
    confirmButton: 'confirm-button-sweet',
  },
})

export const warningInfoMessage = (title, message, messageButton) => Swal.fire({
  iconHtml: iconHtml(exclamationInfoIcon),
  title: title || '',
  html: `${message || ''}`,
  confirmButtonText: messageButton || '',
  customClass: {
    confirmButton: 'confirm-button-sweet',
  },
})

export const successMessageAction = (title, message) => new Promise(resolve => {
  Swal.fire({
    iconHtml: iconHtml(successIcon),
    title: title || '',
    html: `${message || ''}`,
    icon: 'success',
    confirmButtonText: 'Ok',
    customClass: {
      confirmButton: 'confirm-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const warningMessageAction = (title, message, confirmButtonMessage) => new Promise(resolve => {
  Swal.fire({
    iconHtml: iconHtml(exclamationWarningIcon),
    title: title || '',
    html: `${message || ''}`,
    showDenyButton: true,
    confirmButtonText: confirmButtonMessage || '',
    denyButtonText: 'Cancelar',
    reverseButtons: true,
    customClass: {
      confirmButton: 'cancel-button-sweet',
      denyButton: 'confirm-button-sweet ml-1',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const errorMessageAction = (title, message, confirmButtonMessage) => new Promise(resolve => {
  Swal.fire({
    iconHtml: iconHtml(exclamationErrorIcon),
    title: title || '',
    html: `${message || ''}`,
    showDenyButton: true,
    confirmButtonText: confirmButtonMessage || '',
    reverseButtons: true,
    denyButtonText: 'Cancelar',
    customClass: {
      confirmButton: 'confirm-button-sweet',
      denyButton: 'btn btn-outline-danger text-danger mr-2',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const confirmAction = (title, message) => new Promise(resolve => {
  Swal.fire({
    title: title || '',
    html: `${message || ''}`,
    iconHtml: iconHtml(exclamationWarningIcon),
    showDenyButton: true,
    confirmButtonText: 'Sim',
    denyButtonText: 'Não',
    customClass: {
      confirmButton: 'confirm-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const confirmActionTriangleWarn = (title, message) => new Promise(resolve => {
  Swal.fire({
    title: title || '',
    html: `${message || ''}`,
    iconHtml: iconHtml(triangleWarningIcon),
    showDenyButton: true,
    confirmButtonText: 'Sim',
    denyButtonText: 'Não',
    customClass: {
      confirmButton: 'confirm-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const confirmOkActionTriangleWarn = (title, message, textButton) => new Promise(resolve => {
  Swal.fire({
    title: title || '',
    html: `${message || ''}`,
    iconHtml: iconHtml(triangleWarningIcon),
    showDenyButton: false,
    confirmButtonText: textButton || 'Ok',
    width: '630px',
    customClass: {
      confirmButton: 'confirm-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const warningMessageRemoveCompany = username => new Promise(resolve => {
  Swal.fire({
    iconHtml: iconHtml(exclamationWarningIcon),
    title: 'Deseja desvincular a empresa ?',
    html: `Ao desvincular o usuário <strong>${username}</strong> das empresas selecionadas, as mesmas não farão mais parte do seu cadastro de usuário. Para retornar será necessário vincular novamente.`,
    showDenyButton: true,
    confirmButtonText: 'Desvincular',
    denyButtonText: 'Cancelar',
    customClass: {
      confirmButton: 'confirm-button-sweet',
      denyButton: 'cancel-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const warningMessageChangeProfileCase1 = (username, newProfile) => new Promise(resolve => {
  Swal.fire({
    iconHtml: iconHtml(exclamationErrorIcon),
    title: 'Deseja alterar o perfil ?',
    html: `Ao mudar o perfil do usuário <strong>${username}</strong> de <strong>Admin Empresa</strong> para <strong>${newProfile}</strong>, o mesmo perderá o vínculo com as empresas. Para retornar será necessário vincular cada empresa novamente.`,
    showDenyButton: true,
    confirmButtonText: 'Alterar',
    denyButtonText: 'Cancelar',
    customClass: {
      confirmButton: 'confirm-button-sweet',
      denyButton: 'cancel-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const warningMessageChangeProfileCase2 = (username, oldProfile, newProfile) => new Promise(resolve => {
  Swal.fire({
    iconHtml: iconHtml(exclamationErrorIcon),
    title: 'Deseja alterar o perfil ?',
    html: `Ao mudar o perfil do usuário <strong>${username}</strong> de <strong>${oldProfile}</strong> para <strong>${newProfile}</strong>, o mesmo perderá seus vínculos de usuário SESI. Para retornar será necessário vincular cada informação novamente.`,
    showDenyButton: true,
    confirmButtonText: 'Alterar',
    denyButtonText: 'Cancelar',
    customClass: {
      confirmButton: 'confirm-button-sweet',
      denyButton: 'cancel-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})

export const warningMessageRemoveSpreadsheet = () => new Promise(resolve => {
  Swal.fire({
    iconHtml: iconHtml(exclamationWarningIcon),
    title: 'Deseja apagar a importação ?',
    html: 'Confirme a exclusão permanente da importação selecionada. Essa ação não removerá os registros de vacinação realizados.',
    showDenyButton: true,
    confirmButtonText: 'Confirmar',
    denyButtonText: 'Cancelar',
    customClass: {
      confirmButton: 'confirm-button-sweet',
      denyButton: 'cancel-button-sweet',
    },
  }).then(result => {
    if (result.isConfirmed) {
      resolve()
    }
  })
})
