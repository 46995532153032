<template>
  <b-row>
    <!-- valor da dose da empresa -->
    <b-col lg="6" md="6" sm="12">
      <b-form-group>
        <label :for="targetForIdFocus">Valor da dose para empresa:</label>
        <b-input-group>
          <b-input-group-prepend>
            <div class="bg-custom-blue d-flex align-items-center custom-append-input rounded adjustIconInputValue">
              <b-img :src="Ricon" alt="Icone que representa o real" />
            </div>
          </b-input-group-prepend>
          <b-form-input
            class="adjustInput"
            v-money="moneyMask"
            :id="targetForIdFocus"
            placeholder="00,00"
            v-model="valorEmpresaField"
            @keypress="onlyNumber"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col lg="6" md="6" sm="12">
      <p class="font-italic">
        O valor da dose para empresa é aquele que será cobrado da empresa com
        base no estabelecido em contrato.
      </p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
} from "bootstrap-vue";
import Ricon from "@/assets/images/icons/RIcon.svg";
import {
  CampanhaNacionalSetPrecificacaoVacinaGrupoEmpresaValor,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import helpers from "@/helpers";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
  },

  props: {
    moneyMask: {
      type: Object,
      required: true,
    },
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      targetForIdFocus: helpers.geraNumeroAleatorio().toString(),
      valorEmpresaField: 0,
      Ricon
    };
  },
  mounted(){
      this.handleEditCampaignInfo();
  },
  methods: {
    handleEditCampaignInfo(){
      if(this.vacina.grupos){
        const grupoPreco = this.vacina.grupos.find(grupo => grupo.id_grupo == this.grupo.id_grupo)
        
        if(grupoPreco){
          const valorEmpresa = grupoPreco.valor_dose.replace(".", ",")

          this.setValorEmpresa(valorEmpresa);
          this.valorEmpresaField = valorEmpresa;
          
          this.$emit('update', 'grupoPreco-'+grupoPreco.id_grupo)
        }
      }
    },

    setValorEmpresa(valor) {
      CampanhaNacionalSetPrecificacaoVacinaGrupoEmpresaValor({
        idVacina: this.vacina.id_vacina,
        idGrupo: this.grupo.id_grupo,
        valor,
      });
    },

    onlyNumber(event) {
      if (!helpers.keyIsNumber(event)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    valorEmpresaField(valor) {
      this.setValorEmpresa(valor);
    }
  },
};
</script>
