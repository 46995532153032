<template>
  <span v-b-tooltip
        :class="[classe, resolveClasseSituacao(situacaoCadastro)]"
        :title="situacaoCadastro.length > lengthColumnMaximo ? situacaoCadastro : null"
  >
    {{ situacaoCadastro }}
  </span>
</template>

<script>
export default {
  name: "SituacaoCadastro",
  props: {
    situacaoCadastro: {
      type: String,
      required: true,
    },
    lengthColumnMaximo: {
      type: Number,
      required: true,
    },
    classe: {
      type: String,
      required: true,
    },
  },
  methods: {
    resolveClasseSituacao(situacao) {
      switch (situacao) {
        case 'Completo':
          return 'formato_botao_completo'
        case 'Parcial':
          return 'formato_botao_parcial'
        default:
          return 'formato_botao_incompleto'
      }
    },
  },
}
</script>

<style scoped lang="css">
.formato_botao_completo{
  color: #28C76F;
  border-radius: 17px;
  background: #E5F8ED;
  padding: 1px 9px;
  font-weight: 600;
}
.formato_botao_incompleto{
  color: #EA5455;
  border-radius: 17px;
  background: #FCEAEA;
  padding: 1px 9px;
  font-weight: 600;
}
.formato_botao_parcial{
  color: #FF9110;
  border-radius: 17px;
  background: #FF9F431F;
  padding: 1px 9px;
  font-weight: 600;
}
</style>