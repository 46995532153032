<template>
  <div class="content-wrapper">
    <AddCompanyToGroupModal
        v-if="modal.modalAddCompany.modalShow"
        :modalShow="modal.modalAddCompany.modalShow"
        :departamentsOptions="operatorsOptions"
        :isEdit=true
        :companyEditList="companyEditList"
        :idGroup="group.id_grupo"
        @companyAdded="getCompanyAdded"
        @modalClose="closeModalAddCompany"
    />

    <ConfirmModal
        itemIdentifier="usuário"
        :modalShow="modal.modalConfirm.modalShow"
        :typeModal="modal.modalConfirm.typeModal"
        @confirmed="redirectToList"
        @canceled="closeModalConfirm"
    />

    <SucessoModal
        itemIdentifier="Grupo"
        :modalShow="modal.modalSuccess.modalShow"
        :typeModal="modal.modalSuccess.typeModal"
        :caseModal="modal.modalSuccess.caseModal"
        @confirmed="redirectToList"
        @canceled="closeModalSuccess"
    />

    <PageHeader screenName="Grupo de Empresas" :linkItems="linkItems" />

    <main class="card hidden_overflow_tabela">
      <b-overlay :show="loading" rounded="lg" opacity="0.6" class="w-100 mt-1">
        <validation-observer ref="groupForm">
          <b-form>
            <b-row class="py-1 px-2">
              <b-col lg="4" md="4" sm="12">
                <b-button @click="addNewCompany()" variant="primary-button">
                  <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Adicionar nova empresa</span>
                </b-button>
              </b-col>
              <b-col class="mt-1" lg="12" md="12" sm="12">
                <b-alert
                    :show="!canEditCompanies"
                    variant="warning"
                    class="py-1"
                >
                  <span class="ml-1">
                    Não é possível editar DR operadores ou remover empresas quando há uma campanha ativa.
                  </span>
                </b-alert>
              </b-col>
            </b-row>
            <div class="pt-1 mx-2 container_edit_name_group">
              <b-row class="px-2">
                <b-col lg="12" md="12" sm="12">

                  <h2 class="pb-2 edit_grupo_title">Editar grupo</h2>

                  <h5 class="mb-2">Nome do Grupo</h5>

                  <b-form-group
                      label="Insira o nome do grupo *"
                      label-for="name_group"
                      label-class="font_size_label"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Nome do grupo"
                        rules="required"
                    >
                      <b-form-input
                          id="name_group"
                          v-model="form.name_group"
                          class="form-control"
                          maxlength=255
                          placeholder="Nome do grupo"
                      />
                      <small v-if="errors[0]" class="text-danger">
                        {{errors[0]}}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-2 px-2">
                <b-col lg="12" md="12" sm="12">
                  <h5 class="mb-2">Coordenação do Grupo</h5>
                </b-col>
              </b-row>

              <b-row class="px-2 pb-2" >
                <b-col lg="4" md="4" sm="12">
                  <b-form-group
                      label="Administrativo e Financeiro"
                      label-for="financial_admin"
                      label-class="font_size_label"
                  >

                    <v-select
                        class="select_coordination"
                        id="financial_admin"
                        :options="departamentsOptions"
                        v-model="form.financial_admin"
                        placeholder="Selecione"
                        label="descricao"
                        item-text="id_departamento"
                        item-value="id_departamento"
                    />
                  </b-form-group>
                </b-col>

                <b-col lg="4" md="4" sm="12">
                  <b-form-group
                      label="Relacionamento"
                      label-for="relationship"
                      label-class="font_size_label"
                  >
                    <v-select
                        class="select_coordination"
                        id="relationship"
                        :options="departamentsOptions"
                        v-model="form.relationship"
                        placeholder="Selecione"
                        label="descricao"
                        item-text="relationship"
                        item-value="relationship"
                    />
                  </b-form-group>
                </b-col>

                <b-col lg="4" md="4" sm="12">
                  <b-form-group
                      label="Técnico"
                      label-for="technician"
                      label-class="font_size_label"
                  >
                    <v-select
                        class="select_coordination"
                        id="technician"
                        :options="departamentsOptions"
                        v-model="form.technician"
                        placeholder="Selecione"
                        label="descricao"
                        item-text="technician"
                        item-value="technician"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>


            <FilterCompanyList
                :group="groupFilter"
                :errorApi="errorApiSearchParameters"
                :editGroup="true"
                @filterTable="filterTableCompany"
            />

          </b-form>
        </validation-observer>

        <CompanyList
            :companiesEdit="form.companiesEdit"
            @companyEdit="companyEdit"
            :canEditCompanies="canEditCompanies"
            :clear="clear"
            :filterProp="filter.filter"
            :companiesFilter="form.companiesFilter"
        />
      </b-overlay>
      <b-row class="p-2">
        <b-col lg="12" md="12" sm="12">
          <section>
            <b-button id="save-group"
                      class="mr-2"
                      variant="custom-blue"
                      :disabled="loadingButton"
                      @click.prevent="editGroup()"

            >
              <feather-icon icon="CheckIcon" class="mr-50"/>
              <span class="align-middle">Salvar grupo</span>
              <span v-if="loadingButton" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
            </b-button>
            <b-button
                id="cancel-create-group"
                variant="outline-danger"
                :disabled="loadingButton"
                @click.prevent="openModalConfirm"
            >
              <feather-icon icon="XIcon" class="mr-50"/>
              <span class="align-middle">Cancelar</span>
            </b-button>
          </section>
        </b-col>
      </b-row>
    </main>
  </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BSpinner, BFormDatepicker, BFormTextarea, BOverlay, BAlert } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate"
import VueCleave from 'vue-cleave-component'
import vSelect from "vue-select"
import CompanyList from '@/views/custom-pages/GrupoEmpresa/Components/CompanyList.vue'
import AddCompanyToGroupModal from './Components/AddCompanyToGroupModal.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import {
  setIdCompanyGroup,
  setCompanyGroup,
  setCompanyGroupArray
} from '@core/utils/store/setStore';
import { getIdCompanyGroup, getCompanyGroup, getCompanyGroupFilter, getCompanyWithOperators } from '@core/utils/store/getStore';
import { actions, subjects } from "@/libs/acl/rules";
import FilterCompanyList from '@/views/custom-pages/GrupoEmpresa/Components/FilterCompanyList.vue'
export default {
  title: "Editar grupo empresa",

  components: {
    BForm, BCol, BRow, BFormGroup, BFormInput,
    BButton, BTable, CustomPagination, PageHeader,
    ValidationProvider, VueCleave, BFormDatepicker, vSelect, BFormTextarea,
    ValidationObserver, BSpinner, BOverlay, CompanyList, AddCompanyToGroupModal,
    ConfirmModal, SucessoModal, BAlert, FilterCompanyList


  },

  data() {
    return {
      linkItems: [
        {
          name: 'Listagem de grupos',
          routeName: 'group-company-list'
        },
        {
          name: 'Grupos de Empresas',
          active: true
        }
      ],
      errorApiSearchParameters: {
        identificador_empresa: null
      },
      form: {
        name_group: "",
        financial_admin: null,
        relationship: null,
        technician: null,
        companiesEdit: [],
        companiesFilter: [],
      },
      modal: {
        modalAddCompany: {
          modalShow: false,
          editMode: false,
          companyEdit: null,
        },
        modalConfirm: {
          modalShow: false,
          typeModal: 'danger'
        },
        modalSuccess: {
          modalShow: false,
          typeModal: 'success',
          caseModal: 'edicao'
        }
      },
      company_added : null,
      financialAdminOptions: [],
      relationshipOptions: [],
      technicianOptions: [],
      departamentsOptions: [],
      operatorsOptions: [],
      companyGroups: [],
      loading: false,
      group: [],
      loadingButton: false,
      canEditCompanies: true,
      clear: false,
      groupFilter: null,
      filter: false,
      companyEditList: false,
    }
  },
  mounted() {
    this.getDepartment()
    const hasIdEntityRouteParam = this.$helpers.verifyIfHasIdEntityRouteParam(getIdCompanyGroup(), this.$route.params.idGrupo);
    if(hasIdEntityRouteParam) {
      setIdCompanyGroup(this.$route.params.idGrupo);
    }
    this.loadInfoGroup(getIdCompanyGroup())

    this.verifyPermission()
  },
  methods: {
    getDepartment() {
      this.$http.get(this.$api.getDepartmentNational()).then(departaments => {
        this.departamentsOptions = departaments.data
        this.operatorsOptions = departaments.data.filter(departament => departament.uf)
      })
    },
    loadInfoGroup(idGrupo, filter = false) {
      this.errorApiSearchParameters.identificador_empresa = null
      this.filter = filter
      this.loading = true;
      const parameters = {
        nome_empresa: filter.nome_fantasia ?? null,
        identificador_empresa: filter.identificador_empresa ?? null,
        operador: filter.operador ?? null,
        status: filter.status ?? null,
        pagina: filter.pagina ?? filter.pagina,
      }

      if (filter.clear) {
        this.clear = filter.clear
      }

      this.$http.get(this.$api.getAndPutAndDeleteGrupo(idGrupo), { params: parameters }).then(({data}) => {
        this.groupFilter = data
        if(!filter) {
          this.group = data
        }
        this.loadForm(data);
        this.loading = false;
      }).catch(({ response: { data } }) => {
        const error = data.errors
        this.errorApiSearchParameters.identificador_empresa = error.identificador_empresa ? error.identificador_empresa[0] : ''
        this.filtering = false
        this.loading = false;
      })

    },
    loadForm(data){
      this.form.name_group = data.descricao
      this.canEditCompanies = data.campanhas_ativas == 0
      this.form.financial_admin = this.setDepartment(data.departamento_administrativo)
      this.form.relationship = this.setDepartment(data.departamento_relacionamento)
      this.form.technician = this.setDepartment(data.departamento_tecnico)
      this.form.companiesEdit = data.empresa_operador
      this.form.companiesFilter = this.group
    },
    filterTableCompany(filter) {
      if(filter.filter || filter.clear) {
        this.loadInfoGroup(getIdCompanyGroup(), filter)
      }
    },
    setDepartment(data) {
      if(data){
        return {
          id_departamento: data.id_departamento,
          descricao: data.descricao
        }
      }
    },
    async editGroup() {
      this.loadingButton = true
      this.$refs.groupForm.validate().then(async success => {
        if(success) {
          const parameters = {
            descricao: this.form.name_group,
            id_departamento_administrativo: this.form.financial_admin ? this.form.financial_admin.id_departamento : null,
            id_departamento_relacionamento: this.form.relationship ? this.form.relationship.id_departamento : null,
            id_departamento_tecnico: this.form.technician ? this.form.technician.id_departamento : null,
            empresa_operador: this.formatCompanyGroup()
          }
          this.loading = true
          await this.$http.put(this.$api.getAndPutAndDeleteGrupo(getIdCompanyGroup()), parameters).then(() => {
            this.openModalSucess()
          }).catch(({ response: { data, status } }) => {
            this.openModalErrorSave(data, status)
          })

          this.loading = false
        }
        this.loadingButton = false
      })
    },

    formatCompanyGroup() {
      if(this.filter.filter) {
        return getCompanyGroupFilter().map(companyGroup => {
          return {
            id_empresa: companyGroup.id_empresa,
            id_operador: companyGroup.id_operador,
          }
        })
      } else {
        return getCompanyGroup().flatMap(companyGroup => {
          if (Array.isArray(companyGroup.operator)) {
            const seenIds = new Set();
            return companyGroup.operator.filter(operator => {
              if (!seenIds.has(operator.id_empresa_endereco)) {
                seenIds.add(operator.id_empresa_endereco);
                return true;
              }
              return false;
            }).map(operator => ({
              id_empresa: companyGroup.id_empresa,
              id_operador: operator.id_operador ? operator.id_operador : operator.id_departamento,
              id_unidade: operator.id_unidade,
              id_empresa_endereco: operator.id_empresa_endereco
            }));
          } else {
            return {
              id_empresa: companyGroup.id_empresa,
              id_operador: companyGroup.operator,
            };
          }
        });

      }

    },

    addNewCompany() {
      this.modal.modalAddCompany.modalShow = true
    },

    getCompanyAdded(company) {
      setCompanyGroup(company)
    },

    closeModalConfirm() {
      this.modal.modalConfirm.modalShow = false
    },

    openModalConfirm() {
      this.modal.modalConfirm.modalShow = true
    },

    closeModalSuccess() {
      this.modal.modalSuccess.modalShow = false
    },

    closeModalAddCompany() {
      this.modal.modalAddCompany.modalShow = false
      this.companyEditList = false
    },

    openModalSucess() {
      this.modal.modalSuccess.modalShow = true
    },
    companyEdit() {
      this.modal.modalAddCompany.modalShow = true
      this.companyEditList = true
    },

    openModalErrorSave(data, status) {
      if(status === 500) {
        this.$swal({
          title: 'Ocorreu um erro.',
          icon: 'warning',
          iconColor: "red",
          html: '<div>Ocorreu um erro ao tentar editar o grupo.</div>',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: "btn btn-custom-blue"
          },
          buttonsStyling: false
        })
      } else {
        let mensagem = data.errors
        if(data.errors.grupoOperador){
          mensagem = data.errors.grupoOperador[0];
          setCompanyGroupArray(this.form.companiesEdit)
        }
        this.$swal({
          title: 'Ocorreu um erro.',
          icon: 'warning',
          iconColor: "red",
          html: `<div>${mensagem}</div>`,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: "btn btn-custom-blue"
          },
          buttonsStyling: false
        })
      }
    },

    redirectToList() {
      this.modal.modalConfirm.modalShow = false
      this.$router.push({ name: 'group-company-list' });
    },

    verifyPermission() {
      return this.$can(actions.ATUALIZAR, subjects.ATUACAO_BASE_NACIONAL);
    }
  },
  updated() {
    this.clear = false
  },
}

</script>

<style>
  .container_edit_name_group {
    background-color: #f5f6f9;
    border-radius: 5px;
  }

  .select_coordination {
    background-color: white;
  }

  h5 {
    font-weight: 400;
  }

  .edit_grupo_title {
    font-weight: 500;
    font-size: 18px;
  }
</style>